import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../components/Dashboard";
import FormBuilder from "../pages/FormBuilder/FormTable";
import EditorLayout from "../layouts/EditorLayout";
import EditForm from "../pages/FormBuilder/Editform";
import Wizard from "../pages/Wizard/Wizard";
import FormPreview from "../pages/FormBuilder/FormPreview";
import ViewForm from "../pages/FormBuilder/SubmittedFormData";
import ViewWizard from "../pages/Wizard/SubmittedWizardData";
import WizardBuilder from "../pages/Wizard/EditWizardBuilder";
import WizardPreview from "../pages/Wizard/WizardPreview";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route path={"/form"} element={<FormBuilder />} />
        <Route path={"/wizard"} element={<Wizard />} />
        <Route path={"/view-form/:id"} element={<ViewForm />} />
        <Route path={"/view-wizard/:id"} element={<ViewWizard />} />
      </Route>
      <Route element={<PrivateRoute children={<EditorLayout />} />}>
        <Route path={"/edit-form/:id"} element={<EditForm />} />
        <Route path={"/edit-wizard/:id"} element={<WizardBuilder />} />
        <Route path={"/preview-form/:id"} element={<FormPreview />} />
        <Route path={"/preview-wizard/:id"} element={<WizardPreview />} />

      </Route>
    </Routes>
  );
};

export default Router;
