import React from 'react'
import loadable from "@loadable/component"
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../contants/EndPoints';
import { useParams } from 'react-router-dom';

const SubmittedWizardDataComponent = loadable(() => import("../../components/WizardBuilder/SubmittedWizardData"));

const SubmittedWizardData = (props: any) => {
  const { id, row } = useParams()
  console.log(row)
  const { data: formDetailData, isLoading } = useGetHook({
    queryKey: ['from_field'],
    url: `${APIS.FORM}${id}/`,
    params: {
     

    }
  });
  console.log(formDetailData)
  return (
    <SubmittedWizardDataComponent
      fields={formDetailData?.fields}
    />
  )
}

export default SubmittedWizardData