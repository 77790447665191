import React from 'react'
import loadable from "@loadable/component"
import { useParams } from 'react-router-dom';
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../contants/EndPoints';
import usePostHook from '../../customHooks/usePostHook';


const FormPreviewComponent = loadable(() => import("../../components/FormBuilder/FormPreview"));

const FormPreview = () => {
  const { id } = useParams()

  const { data: formFieldPreviewData, isLoading } = useGetHook({
    queryKey: ['from_field'],
    url: `${APIS.FORM_FIELD}${id}/`,
    params: {}
  });
  console.log()
  const formData = JSON.parse(formFieldPreviewData?.form_field || "{}")


  const { mutateAsync } = usePostHook({ queryKey: ["form_data"], navigateURL: null })

  const handleCreateFormData = async (values: any, setError: any) => {
    const url = `${APIS.FORM_DATA}`
    let formData = {
      data: values,
      form: formFieldPreviewData?.form
    }

    try {
      const res = await mutateAsync({ url, formData, setError })

    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      {isLoading ? "" :
        <>
          <FormPreviewComponent
            formPreviewData={formData}
            handleCreateFormData={handleCreateFormData}
          />
        </>
      }
    </>
  )
}

export default FormPreview