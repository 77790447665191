import React from 'react'
import loadable from "@loadable/component"
import { useParams } from 'react-router-dom';
import APIS from '../../contants/EndPoints';
import useGetHook from '../../customHooks/useGetHook';
import useDeleteHook from '../../customHooks/useDeleteHook';

const SubmittedFormDataComponent = loadable(() => import("../../components/FormBuilder/SubmittedFormData"));

const SubmittedFormData = () => {
  const { id, row } = useParams()

  const { data: formDetailData, isLoading } = useGetHook({
    queryKey: ['from_field'],
    url: `${APIS.FORM}${id}/`,
    params: {}
  });
  console.log(formDetailData?.fields)
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["form_data"] })

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.FORM_DATA}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {isLoading ? "" :
        <SubmittedFormDataComponent
          fields={formDetailData?.fields?.[0]?.form_field ?? "{}"}
          formId={id}
          handleDelete={handleDelete}
        />
      }
    </>
  )
}

export default SubmittedFormData