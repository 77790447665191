import React from 'react'
import loadable from "@loadable/component"
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../contants/EndPoints';
import { useParams } from 'react-router-dom';
import usePostHook from '../../customHooks/usePostHook';


const WizardPreviewComponent = loadable(() => import("../../components/WizardBuilder/WizardPreview"));

const WizardPreview = () => {
  const { id } = useParams()
  const { data: wizardPeviewFieldData, isLoading } = useGetHook({
    queryKey: ['wizard'],
    url: `${APIS.FORM}${id}/`,
    params: {
      form_type: 'wizard'
    }
  });
  console.log(wizardPeviewFieldData)
  const { mutateAsync } = usePostHook({ queryKey: ["form_data"], navigateURL: null })

  const handleCreateWizardData = async (values: any, setError: any) => {
    const url = `${APIS.FORM_DATA}`
    let formData = {
      data: values,
      form: id
    }

    try {
      const res = await mutateAsync({ url, formData, setError })

    } catch (err) {
      console.log(err);
    }
  }

  return (
    <WizardPreviewComponent
      wizardPeviewFieldData={wizardPeviewFieldData?.fields}
      handleCreateWizardData={handleCreateWizardData}

    />
  )
}

export default WizardPreview