import loadable from "@loadable/component"
import React, { useState } from 'react'
import usePatchHook from "../../customHooks/usePatchHook";
import APIS from "../../contants/EndPoints";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import usePostHook from "../../customHooks/usePostHook";
import useDeleteHook from "../../customHooks/useDeleteHook";
const WizardBuilderComponent = loadable(() => import("../../components/WizardBuilder/WizardBuilder"));

const WizardBuilder = () => {
  const { id } = useParams()
  const [currentWizard, setCurrentWizard] = useState<any>()

  const { data: wizardFieldData, isLoading } = useGetHook({
    queryKey: ['wizard'],
    url: `${APIS.FORM}${id}/`,
    params: {
      form_type: 'wizard'
    }
  });
  console.log(wizardFieldData,"jihu")
  const { mutateAsync } = usePatchHook({ queryKey: ['wizard'], navigateURL: null })

  const handleCreateWizardBuilder = async (values: any, elements: any, setError: any) => {
    const url = `${APIS.FORM_FIELD}${currentWizard?.id}/`
    let formData = {
      form_field: JSON.stringify({
        properties: values,
        elements: elements,
      })
    }
    try {
      const res = await mutateAsync({ url, formData, setError })
    } catch (err) {
      console.log(err);
    }
  }
  const { mutateAsync: wizardDataMutateAsync } = usePostHook({ queryKey: ["wizard"], navigateURL: null })

  const handleCreateWizardData = async (values: any, elements: any, setError: any) => {
    const url = `${APIS.FORM_FIELD}`
    let formData = {
      title: "wizard",
      form: id
    }
    try {
      const res = await wizardDataMutateAsync({ url, formData, setError })
    } catch (err) {
      console.log(err);
    }
  }

  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["wizard"] })
  const handleDelete = async (pk: string, setOpenRenamePopup: any) => {
    const url = `${APIS.FORM_FIELD}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenRenamePopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  const { mutateAsync: wizardUpdateMutateAsync } = usePatchHook({ queryKey: ["wizard"], navigateURL: null })

  const handleUpdateWizard = async (values: any, selectedField: any, setError: any, setOpenRenamePopup: any) => {
    const url = `${APIS.FORM_FIELD}${selectedField}/`
    let formData = {
      ...values
    }
    console.log(values)
    try {
      const res = await wizardUpdateMutateAsync({ url, formData, setError })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <WizardBuilderComponent
      handleCreateWizardBuilder={handleCreateWizardBuilder}
      wizardFieldData={wizardFieldData?.fields}
      handleCreateWizardData={handleCreateWizardData}
      handleDelete={handleDelete}
      handleUpdateWizard={handleUpdateWizard}
      currentWizard={currentWizard}
      setCurrentWizard={setCurrentWizard}
    />
  )
}

export default WizardBuilder