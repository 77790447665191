import React from 'react'
import loadable from "@loadable/component"
import usePatchHook from '../../customHooks/usePatchHook';
import APIS from '../../contants/EndPoints';
import { useParams } from 'react-router-dom';
import useGetHook from '../../customHooks/useGetHook';

const EditFormComponent = loadable(() => import("../../components/FormBuilder/FormBuilder"));

const EditForm = () => {
  const { id } = useParams()

  const { data: formFieldData, isLoading } = useGetHook({
    queryKey: ['froms'],
    url: `${APIS.FORM_FIELD}${id}/`,
    params: {}
  });

  const { mutateAsync } = usePatchHook({ queryKey: ["form"], navigateURL: null })

  const handleCreateFormBuilder = async (values: any, elements: any, setError: any) => {
    const url = `${APIS.FORM_FIELD}${id}/`
    let formData = {
      form_field: JSON.stringify({
        properties: values,
        elements: elements,
        
      })
    }
    console.log(values)
    try {
      const res = await mutateAsync({ url, formData, setError })

    } catch (err) {
      console.log(err);
    }
  }
  return (
    <EditFormComponent
      handleCreateFormBuilder={handleCreateFormBuilder}
      formFieldData={formFieldData}
    />
  )
}

export default EditForm