import React from "react";
import DashboardIcon from "../assets/icons/dashboard.svg";
import TagsIcon from "../assets/icons/category.svg";
import { NavLink, Outlet } from "react-router-dom";
//@ts-ignore
import { Layout } from "@dibtech/styleguide";

const navigation = [
  { name: "Dashboard", href: "/", icon: DashboardIcon },
  { name: "Form", href: "/form", icon: TagsIcon },
  { name: "Wizard", href: "/wizard", icon: TagsIcon }
];

const DashboardLayout = () => {
  return (
    <Layout
      children={<Outlet />}
      navigationComponent={
        <nav className="px-2 space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={{
                pathname: item.href,
                search: `?paramName=${item?.name}` 
              }}
              className={({ isActive, isPending }) => {
                return `${isActive ? "bg-[#5b6ab0] text-white" : isPending ? "text-indigo-100 hover:bg-indigo-600" : ""} group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md`;
              }}
            >
              <img src={item.icon} alt="" className="text-indigo-300" />
              {item.name}
            </NavLink>
          ))}
        </nav>
      }
    />
  );
};

export default DashboardLayout;
