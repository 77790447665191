const APIS = {
  // Pages
  FORM: `/form/`,
  FORM_FIELD: `/form-field/`,
  FORM_DATA: `/form-data/`


};

export default APIS;
