import React from 'react'
import loadable from "@loadable/component"
import usePostHook from '../../customHooks/usePostHook';
import APIS from '../../contants/EndPoints';
import { useNavigate } from 'react-router-dom';
import useDeleteHook from '../../customHooks/useDeleteHook';
import usePatchHook from '../../customHooks/usePatchHook';


const WizardComponent = loadable(() => import("../../components/Wizard/Wizard"));

const Wizard = () => {
  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["wizard"], navigateURL: null })
  const navigate = useNavigate()
  const handleCreateWizard = async (values: any, setError: any) => {
    const url = APIS.FORM
    let formData = {
      form_type: "wizard",
      ...values
    }
    try {
      const res = await mutateAsync({ url, formData, setError })
    } catch (err) {
      console.log(err);
    }
  }
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["wizard"] })

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.FORM}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  const { mutateAsync: EditWizardMutateAsync } = usePatchHook({ queryKey: ["wizard"], navigateURL: null })

  const handleEditWizard = async (values: any, setError: any, selectedValues: any, setIsOpenEdit: any) => {
    const url = `${APIS.FORM}${selectedValues?.id}/`
    let formData = {
      form_type: "wizard",
      ...values
    }
    try {
      const res = await EditWizardMutateAsync({ url, formData, setError })
      setIsOpenEdit(false)
    } catch (err) {
      console.log(err);
    }
  }
  const { mutateAsync: patchActiveMutateAsync } = usePatchHook({ queryKey: ["wizard"], navigateURL: null })

  const handleIsActive = async (e: any, id: any) => {

    const payload = {
      is_active: e.target.checked
    };

    const url = `${APIS.FORM}${id}/`;
    const formData = payload
    try {
      await patchActiveMutateAsync({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }
  const { mutateAsync: patchPublicMutateAsync } = usePatchHook({ queryKey: ["wizard"], navigateURL: null })

  const handleIsPublic = async (e: any, id: any) => {

    const payload = {
      is_public: e.target.checked
    };

    const url = `${APIS.FORM}${id}/`;
    const formData = payload
    try {
      await patchPublicMutateAsync({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }
  const { mutateAsync: patchSMSMutateAsync } = usePatchHook({ queryKey: ["wizard"], navigateURL: null })

  const handleIsSMS = async (e: any, id: any) => {

    const payload = {
      is_sms: e.target.checked
    };

    const url = `${APIS.FORM}${id}/`;
    const formData = payload
    try {
      await patchSMSMutateAsync({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }
  const { mutateAsync: patchEmailMutateAsync } = usePatchHook({ queryKey: ["wizard"], navigateURL: null })

  const handleIsEmail = async (e: any, id: any) => {

    const payload = {
      is_email: e.target.checked
    };

    const url = `${APIS.FORM}${id}/`;
    const formData = payload
    try {
      await patchEmailMutateAsync({ url, formData });

    } catch (e) {
      console.log(e)
    }
  }


  return (
    <WizardComponent
      handleCreateWizard={handleCreateWizard}
      handleDelete={handleDelete}
      handleIsActive={handleIsActive}
      handleIsPublic={handleIsPublic}
      handleIsSMS={handleIsSMS}
      handleIsEmail={handleIsEmail}
      handleEditWizard={handleEditWizard}
    />
  )
}

export default Wizard